import Head from "next/head"
import Script from "next/script"
import { useEffect } from "react";

export default function DefaultHead({ gtmID, children }) {
  useEffect(() => {
    const setFavicon = () => {
      const isDarkMode = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
      const faviconPath = isDarkMode ? "/assets/favicons/dark.png" : "/assets/favicons/light.png";
      const link = document.querySelector("link[rel='icon']");
      link.href = faviconPath;
    };
    
    setFavicon();
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", setFavicon);
    
    return () => {
      window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", setFavicon);
    };
  }, []);
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="/assets/favicons/light.png"
          rel="icon"
        />
        <link
          rel="stylesheet"
          href="https://use.typekit.net/jrr3dbo.css"></link>
        {children}
      </Head>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-${gtmID}');
      `}
      </Script>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-${gtmID}" height="0" width="0" style="display: none; visibility: hidden;" />`,
        }}
      />
    </>
  )
}
