import Script from "next/script"

export default function Scripts() {
  return (
    <>
      {/* CookieBot */}
      {/* <Script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        strategy="lazyOnload"
        data-cbid=""
        data-blockingmode="auto"
        type="text/javascript"
      /> */}
      {/* Google tag (gtag.js) */}
      {process.env.NODE_ENV == "production" && (
        <>
          <Script
            strategy="lazyOnload"
            src="https://www.googletagmanager.com/gtag/js?id="
          />
          <Script async id="google-analytics">
            {`
                        window.dataLayer = window.dataLayer || []
                        function gtag() {
                            dataLayer.push(arguments)
                        }
                        gtag("js", new Date())
                        
                        gtag("config", "")
                        `}
          </Script>
        </>
      )}
    </>
  )
}
