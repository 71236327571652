import DefaultHead from "components/DefaultHead"
import Scripts from "components/Scripts"
import { AnimatePresence, isBrowser, motion } from "framer-motion"
import { useRouter } from "next/router"
import { useEffect } from "react"
import ExitPreview from "../components/ExitPreview"
import { PageDataProvider } from "../lib/usePageData"
import useWindowSize from "../lib/useWindowSize"
import { TranslationsProvider } from "../lib/useTranslations.client"

import "../styles/globals.scss"

function MyApp({ Component, pageProps }) {
  const { asPath } = useRouter()
  const { globalSettings: { gtmID } = {} } = pageProps
  const { height } = useWindowSize()

  useEffect(() => {
    document.querySelector(":root").style.setProperty("--wh", `${height}px`)
  }, [height])

  useEffect(() => {
    if (asPath.includes("#") && typeof window !== "undefined") {
      setTimeout(() => {
        window.scrollTo({ top: window.scrollY - 50 })
      }, 20)
    }
  }, [])

  return (
    <PageDataProvider value={pageProps}>
      <TranslationsProvider value={pageProps.globalSettings?.stringTranslations}>
        <DefaultHead gtmID={gtmID} />
        <AnimatePresence>
          <motion.div
            key={pageProps?.data?.page?._id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.5 } }}
            exit={{ opacity: 0 }}
            className=" bg-white text-black-almost min-h-screen h-full font-serif"
          >
            <Component {...pageProps} />
            <ExitPreview preview={pageProps.preview} />
            <Scripts />
          </motion.div>
        </AnimatePresence>
      </TranslationsProvider>
    </PageDataProvider>
  )
}

export default MyApp
