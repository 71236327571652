function slugify(string) {
    return string
        .toLowerCase()
        .trim()
        .replaceAll(/ /g, '-')
}

export function getT(translations) {
    return function t(key) {
        const segments = key.split('.');
        const translationGroup = translations?.translationGroups?.find(translationGroup =>
            slugify(translationGroup.key.current) === segments[0]
        );
        const translation = translationGroup?.translations.find(translation =>
            slugify(translation.key.current) === segments[1]
        );

        if (!translation) {
            return key;
        }

        return translation.value;
    }
}