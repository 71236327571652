import { createContext, useContext } from 'react';
import { getT } from 'lib/useTranslationsHelpers'

export const TranslationsContext = createContext();

export const TranslationsProvider = TranslationsContext.Provider;

export const useTranslations = () => {
    const translations = useContext(TranslationsContext);

    return { t: getT(translations) }
}

